import { SearchPage } from 'Components'
import TemplateSearchPage from 'Stores/_default-store/Templates/SearchPage.rt'
import { SearchBox } from 'Components'
import TemplateSearchBox from 'Stores/_default-store/Templates/SearchBox.rt'
import { SearchHeader } from 'Components'
import { FacetPanel } from 'Components'
import { SearchResult } from 'Components'
import { FacetDialog } from 'Components'
import TemplateFacetDialog from 'Stores/_default-store/Templates/FacetDialog.rt'

export const compMap = {
  SearchPage,
SearchBox,
SearchHeader,
FacetPanel,
SearchResult,
FacetDialog
} 
export const tplMap = {
  'SearchPage': TemplateSearchPage,
'SearchBox': TemplateSearchBox,
'FacetDialog': TemplateFacetDialog
};