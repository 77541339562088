import customUriParser from './uriParser.js';

function InitFunc() {
  const oldURIviewField = 'view';
  const viewValuesMap = {
    strip: 'cm_viewgrid',
    row: 'cm_viewlist',
  };

  const view = window.location.hash.includes(oldURIviewField)
    ? viewValuesMap[window.location.hash.split(`${oldURIviewField}=`)[1].split('&')[0]]
    : 'cm_viewlist'; // old default
  localStorage.setItem('cm_view', JSON.stringify(view));
}

function updateCallback() {
  const { query } = window.Convermax.getSearchRequest();
  const partBuilderLink = window.document.getElementById('partBuilderLink');
  if (partBuilderLink !== null) {
    partBuilderLink.textContent = query.substring(0, 3);
  }
  window.cmSettings = { query };
}

export default {
  platform: 'shift4shop',
  customUriParser,
  InitFunc,
  defaultView: 'cm_viewlist',
  searchPageUrl: '/New-Search_ep_2254-1.html',
  SearchRequestDefaults: {
    pageSize: 24,
  },
  pagination: {
    centralRange: 1,
  },
  product: {
    noImageSrc: '/assets/images/noimage.gif',
  },
  Widgets: [
    {
      name: 'SearchPage',
      location: { replace: '#cm_results' },
      template: 'SearchPage',
    },
    {
      name: 'MainSearchBox',
      type: 'SearchBox',
      location: {
        selector: '#FRAME_SEARCH #searchBox',
        class: 'cm_search-box-root_container cm_search-box-root_container__primary',
      },
      template: 'SearchBox',
      disableDropdown: true,
    },
    {
      name: 'SearchBox',
      location: {
        selector: '#search .search-area #searchBox',
        class: 'cm_search-box-root_container cm_search-box-root_container__secondary',
      },
      template: 'SearchBox',
      disableDropdown: true,
    },
    {
      name: 'SearchHeader',
      sortEntries: {
        'relevance': 'Relevancy',
        'Price': 'Price: Low to High',
        'Price:desc': 'Price: High to Low',
        'DateCreated:desc': 'Newest',
        'DateCreated': 'Oldest',
        'Name': 'Title',
      },
      pageSizes: [6, 12, 24, 48, 96],
    },
    {
      name: 'FacetPanel',
    },
    {
      name: 'SearchResult',
      updateCallback,
    },
    {
      name: 'FacetDialog',
      template: 'FacetDialog',
    },
  ],
};
