//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-44:_7108,_2716,_8824,_3528,_888,_6715,_9100,_3888;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-44')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-44', "_7108,_2716,_8824,_3528,_888,_6715,_9100,_3888");
        }
      }catch (ex) {
        console.error(ex);
      }