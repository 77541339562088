//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-44:_5808,_5388,_188,_6980,_4028,_2940,_6692,_9544;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-44')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-44', "_5808,_5388,_188,_6980,_4028,_2940,_6692,_9544");
        }
      }catch (ex) {
        console.error(ex);
      }