const pageNumberField = 'page';
const sortField = 'sort';

const serviceFieldsNamesMap = {
  showed: 'pageSize',
  query: 'query',
};

const selectionFieldsNamesMap = {
  CurrentPrice: 'Price',
  ManufacturerName: 'Manufacturer',
};

export default {
  parsingNeeded() {
    const { search: locationSearch, hash: locationHash } = window.location;

    return !!locationHash;
  },
  parse() {
    const { search: locationSearch, hash: locationHash } = window.location;

    let pageNumber;
    let sort;
    const serviceFields = {};
    const selection = [];
    const facets = {};

    locationHash
      .replace('#', '')
      .split('&')
      .forEach((param) => {
        const [field, value] = param.split('=');

        // https://www.smcpneumatics.com/New-Search_ep_2254-1.html#query=smc&showed=32
        if (serviceFieldsNamesMap[field]) {
          serviceFields[serviceFieldsNamesMap[field]] = value;
        }

        // https://www.smcpneumatics.com/New-Search_ep_2254-1.html#query=smc&page=2
        // https://www.smcpneumatics.com/New-Search_ep_2254-1.html#query=smc&page=-1 //values < 0 omitted
        if (field === pageNumberField && parseInt(value) >= 0) {
          pageNumber = parseInt(value);
        }

        // https://www.smcpneumatics.com/New-Search_ep_2254-1.html#query=smc&showed=32&sort=CurrentPrice:asc&page=0
        if (field === sortField) {
          const sortFieldName = value.substring(0, value.indexOf(':'));
          const preprocessedValue = value.replace(':asc', '');
          if (Object.keys(selectionFieldsNamesMap).includes(sortFieldName)) {
            sort = preprocessedValue.replace(sortFieldName, selectionFieldsNamesMap[sortFieldName]);
          } else {
            sort = preprocessedValue;
          }
        }

        // Key part of key-value pair of facet selection
        // https://www.smcpneumatics.com/New-Search_ep_2254-1.html#fld0=InStock&sel0=No
        if (field.startsWith('fld')) {
          const filedNum = field.substring(3);
          if (!facets[filedNum]) {
            facets[filedNum] = {};
          }
          facets[filedNum].Key = value;
        }

        // See prev. example, this is value part of key-value pair of facet selection
        if (field.startsWith('sel')) {
          const filedNum = field.substring(3);
          if (!facets[filedNum]) {
            facets[filedNum] = {};
          }
          facets[filedNum].Value = value;
        }
      });

    for (const v of Object.values(facets)) {
      if (v.Key && v.Value) {
        // Rename selection fields
        let preprocessedKey = v.Key;
        if (Object.keys(selectionFieldsNamesMap).includes(preprocessedKey.trim())) {
          preprocessedKey = preprocessedKey.replace(
            preprocessedKey.trim(),
            selectionFieldsNamesMap[preprocessedKey.trim()],
          );
        }

        // Convert ranges
        const preprocessedValue = decodeURI(v.Value).replace(/\[([\d.]+)\s*TO\s*([\d.]+)\]/, '$1 TO $2');

        // Convert Bool value stored as string
        if (preprocessedKey === 'InStock') {
          selection.push({ field: preprocessedKey, term: preprocessedValue === 'Yes' ? 'True' : 'False' });
        } else {
          selection.push({ field: preprocessedKey, term: preprocessedValue });
        }
      }
    }

    // use JSON.parse(JSON.stringify()) to filter undefined
    return JSON.parse(JSON.stringify({ selection, ...serviceFields, pageNumber, sort }));
  },
};
